<template>
  <div>
    <get-code
      v-if="step == 1"
      :mode="'forgot-password'"
      @submit="onGetCode"
    />
    <validate-code
      v-if="step == 2"
      :mode="'forgot-password'"
      :channel="channel"
      :value="value"
      :uuid="uuid"
      :accounts="accounts"
      @cancel="onCancel"
      @setUser="onSetUser"
      @submit="onValidateCode"
      @resend="onResend"
    />
    <select-account
      v-if="showAccountSelector"
      :accounts="accounts"
      @submit="onSetUser"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import GetCode from './GetCode'
import ValidateCode from './ValidateCode'
import SelectAccount from './SelectAccount'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'ForgotPassword',
  components: {
    GetCode, ValidateCode, SelectAccount
  },
  mixins: [Helpers],
  data () {
    return {
      step: 1,
      channel: '',
      value: '',
      access_code: '',
      uuid: '',
      accounts: [],
      showAccountSelector: false
    }
  },
  watch: {
    uuid (newValue) {
      if (this.showAccountSelector && newValue) {
        this.showAccountSelector = false
        this.$router.push({ name: 'ResetPassword' })
      }
    }
  },
  created () {
    if (this.$route.query && this.$route.query.u) {
      this.$store.commit('security/SET_LOGIN_USERNAME', this.$getDecodedId(this.$route.query.u))
    }

    if (this.$route.query && this.$route.query.email) {
      this.channel = 1
      this.value = this.$getDecodedId(this.$route.query.email)
    }
  },
  methods: {
    onResend () {
      this.channel = ''
      this.value = ''
      this.uuid = ''
      this.accounts = []
      this.step = 1
    },
    onGetCode (data) {
      this.channel = data.channel
      this.value = data.value
      this.accounts = data.accounts

      if (this.accounts.length <= 1) {
        this.uuid = data.uuid
      }

      this.step = 2
    },
    onValidateCode (accessCode) {
      this.$store.commit('security/SET_ACCESS_CODE', accessCode)
      this.step = 3
      if (!this.uuid) {
        this.showAccountSelector = true
      } else {
        this.$router.push({ name: 'ResetPassword' })
      }
    },
    onCancel () {
      this.$router.push({ name: 'Login' })
    },
    onSetUser (user) {
      this.$store.commit('security/SET_ACCESS_CODE_UUID', user.uuid)
      this.uuid = user.uuid
    }
  }
}
</script>
